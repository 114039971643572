import { CMS_INSTANCE } from '@/lib/api'
import { getMeta } from '@/utils/getMeta'
import { setCookie } from 'cookies-next'
import { create } from 'zustand'

const useMeta = create((set, get) => ({
  locale: 'th',
  metaSettings: null,
  metaInfo: null,
  centerGroups: null,
  centers: null,
  taxonomies: null,
  metaIsLoading: true,
  seoRedirect: null,
  isExactLocale: (locale = '') => {
    return get().locale === locale
  },
  fetchMeta: async (locale = 'th') => {
    // if locale contains sw.js, return
    if (locale.includes('.')) {
      return null
    }
    set({ locale })
    return getMeta(locale)
  },
  setPopupCookie: (popupCookieName) => {
    // Cookies 10 days
    let days = 10
    setCookie(popupCookieName, true, {
      expires: new Date(Date.now() + days * 24 * 3600000),
    })
  },
  uploadFile: async (file, token) => {
    const formData = new FormData()
    const fileName = file?.name
    if (!fileName) return
    const fileExt = fileName?.split('.')?.pop()
    const fileNameWithoutExt = fileName?.replace(/\.[^/.]+$/, '')
    const newFileName = `${fileNameWithoutExt}.${fileExt}`
    formData.append('file', file, newFileName)
    formData.append('token', token)
    const { data } = await CMS_INSTANCE.post(
      '/v1/secure-media-upload',
      formData,
      {
        headers: {
          'Content-Disposition': 'attachment; filename="' + newFileName + '"',
          'Content-Type': file.type,
        },
      }
    )
    return data
  },
  getCenter: (value, key = '_slug') => {
    return get().centers?.find((center) => center[key] === value)
  },
  getTaxonomy: (value = '', key = '_id', category = '') => {
    console.log(get().taxonomies)
    return get().taxonomies?.[category]?.terms.find((x) => x[key] === value)
  },
}))

export default useMeta
